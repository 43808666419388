<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :min-width="windows[tab].width"
    :max-width="windows[tab].width"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      class="elevation-3 d-flex flex-column rounded-lg"
      :min-height="546"
      :loading="!crossroad"
    >
      <v-card-title>
        <span
          v-if="crossroad"
          class="primary--text"
        >
          {{ crossroadName }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text>
        <v-tabs
          v-model="tab"
          centered
          text
          color="secondary"
          background-color="transparent"
          hide-slider
        >
          <v-tab
            v-for="(window, i) in windows"
            :key="`window-${i}`"
          >
            {{ $t(window.text) }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <v-card-text
        class="flex-grow-1 d-flex flex-column"
      >
        <component
          :is="selectedWindow.component"
          :value="crossroadId"
          v-bind="selectedWindow.attrs"
          v-on="selectedWindow.listeners"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { getCrossroadName } from '@utils/crossroad';

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      Information: () => import('@/views/admin/components/dashboard/crossroadModal/window/Information'),
      // eslint-disable-next-line vue/no-unused-components
      History: () => import('@/views/admin/components/dashboard/crossroadModal/window/History'),
      Stats: () => import('@/views/admin/components/dashboard/crossroadModal/window/Stats'),
    },

    props: {
      stationId: {
        type: [String, Number],
        required: true,
      },
      crossroadId: {
        type: [String, Number],
        required: true,
      },
    },

    apollo: {
      crossroad: {
        query: require('@gql/views/admin/components/dashboard/crossroad-modal/getCrossroad.gql'),
        client: 'stations',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            id: this.crossroadId,
          };
        },
        update: function (data) {
          return data.getCrossroad;
        },
      },
    },

    data: function () {
      return {
        model: false,
        tab: 0,
        windows: [
          {
            text: 'messages.views.admin.components.dashboard.crossroadModal.crossroadModal.windows.info',
            width: 1280,
            component: 'Information',
            attrs: {
              stationId: this.stationId,
              crossroadId: this.crossroadId,
            },
          },
          {
            text: 'messages.views.admin.components.dashboard.crossroadModal.crossroadModal.windows.history',
            width: 1280,
            component: 'History',
          },
          {
            text: 'messages.views.admin.components.dashboard.crossroadModal.crossroadModal.windows.stats',
            width: 1280,
            component: 'Stats',
          },
        ],
      };
    },

    computed: {
      selectedWindow () {
        return this.windows[this.tab];
      },
      crossroadName () {
        return getCrossroadName(this.crossroad, this.crossroad.station);
      },
    },

    watch: {
      model: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },

    mounted () {
      this.model = this.crossroadId !== null;
    },
  };
</script>
